// 1. The basic part of the example
      var L;

      window.onload = function() {
            L.mapquest.key = 'RT7DIzZRtIBDQwFbOEHkDOOQwyAiyv1R';
            
            // 'map' refers to a <div> element with the ID map
            var map = L.mapquest.map('footer-location', {
            center: [ 17.986329388, -76.82083005],
            layers: L.mapquest.tileLayer('hybrid'),
            zoom: 11
            });
            map.addControl(L.mapquest.control());
            L.marker([17.986829388, -76.82483005], {
                icon: L.mapquest.icons.marker({
                    primaryColor: '#22407F',
                    secondaryColor: '#3B5998',
                    shadow: true,
                    size: 'sm',
                    symbol: 'A'
                })
            })
            .bindPopup('This is Tinson Pen Aerodrome!')
            .addTo(map);
        }